import { fetchWrapper } from '@/helpers';

const ruta = process.env.VUE_APP_ROOT_API;

export default class MovimientoInventario {

  async nuevoMovimiento(movimiento) {
    return await fetchWrapper.post(`${ruta}/movimiento/nuevoMovimiento`, movimiento);
  }

  async imprimirMov(id) {
    return await fetchWrapper.postPdf(`${ruta}/movimiento/nuevoMovimiento/imprimirid/${id}`);
  }

  async imprimirPDF(traspasos) {
    return await fetchWrapper.postPdf(`${ruta}/movimiento/traspasos/imprimir`, traspasos);
  }
  async filtrarTraspasos(datos) {
    return await fetchWrapper.post(`${ruta}/movimientos/traspasos/filtrar`, datos);
  }
}